import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export type TDate = string;
export type TDateRange = {
  start: TDate;
  end: TDate;
};

export const withinDateRange = (
  dateRange: TDateRange,
  date?: TDate,
): boolean => {
  return dayjs(date).isBetween(dateRange.start, dateRange.end, 'date', '[]');
};
