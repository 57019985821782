import {
	LsBanner,
	LsBannerItemAssets,
	LsBannerItemPromotion,
} from '@lsoft/shared/components/ui/LsBanner';
import {
	PromotionsContainer,
	PromotionsDataIterator,
} from '@lsoft/shared/components/Promotion/container/Promotions';
import { PromotionContainer } from '@lsoft/shared/components/Promotion/container/Promotion';
import React from 'react';

const braderieAssets = {
	// 1280x353
	fullAsset: {
		asset_type: 'slider_full',
		url: {
			ALL: '/images/banner/braderie-banner-ai.jpeg',
		},
		url_type: 'RELATIVE',
		caption: {
			ALL: 'braderie',
		},
		active: true,
	},

	// 600x250
	slimAsset: {
		asset_type: 'slider_slim',
		url: {
			ALL: '/images/banner/braderie-banner-ai.jpeg',
		},
		url_type: 'RELATIVE',
		caption: {
			ALL: 'braderie',
		},
		active: true,
	},
	dateRange: {
		start: '2024-09-23',
		end: '2024-09-28',
	},
};

export const Banner = () => {
	return (
		<LsBanner className={'w-full'}>
			<LsBannerItemAssets {...braderieAssets} />
			<PromotionsContainer active={true} without_empty>
				<PromotionsDataIterator>
					<PromotionContainer>
						<LsBannerItemPromotion />
					</PromotionContainer>
				</PromotionsDataIterator>
			</PromotionsContainer>
		</LsBanner>
	);
};
